$yellow: #f2d30c
$secondary: #212020

@font-face
  font-family: Silkscreen
  src: url(../fonts/silkscreen-regular.ttf)

*
  font-family: "Fira Code", monospace
  color: $yellow

html
  background-color: $secondary
  margin: 0 30px 0 30px

a
  text-decoration: none
  position: relative

em
  font-size: 0.75em

.decoration
  text-shadow: 0 0 10px orange

header
  display: flex
  align-items: center
  gap: 3em
  @media (max-width: 500px)
    flex-direction: column
    gap: 0
    margin-bottom: 70px

  nav
    display: flex
    flex-wrap: wrap
    gap: 15px
    user-select: none

    a
      transition: all 0.1s ease

      &:hover
        transform: scale(1.1)
        color: #fff

      @media (max-width: 500px)
        line-height: 0.1
        justify-content: center
        row-gap: 30px

  h1
    color: $yellow
    font-family: Silkscreen, monospace
    font-size: 4em
    user-select: none
    @media (max-width: 500px)
      font-size: 2em

h2
  color: #f2740c

h2:before
  content: "> "

h3
  margin-left: 0.6em
  color: #f2a50c

section h2:not(:first-of-type)
  margin-top: 60px

.accueil
  width: 70vw
  margin: auto

  a:hover
    text-shadow: 0 0 4px white

  p, li
    color: white
    text-align: justify

  .langages, .frameworks
    margin-bottom: 25px

.projects
  display: flex
  flex-wrap: wrap
  flex-direction: column
  justify-items: center
  justify-content: center
  align-items: center
  gap: 25px

  .project-card
    border-radius: 20px
    border: solid $yellow
    padding: 15px
    width: 70vw
    background-color: #181818

    &_title
      font-size: 1.3em
      line-height: 0
      color: #f2a50c
      @media (max-width: 500px)
        text-align: center

    &_description
      font-size: 0.9em
      line-height: 1
      color: #f5f5f5
      margin-left: 10px
      @media (max-width: 500px)
        margin-left: 0

    &_tools
      display: flex
      justify-content: right
      gap: 5px
      flex-wrap: wrap
      margin-top: 25px
      margin-bottom: 10px
      @media (max-width: 500px)
        justify-content: center

      p
        font-size: 0.9em
        padding: 7px 15px 7px 15px
        border: solid 2px $yellow
        border-radius: 15px
        background-color: #000000
        white-space: nowrap
        margin: 0

.nomatch
  display: flex
  align-items: center
  flex-direction: column
  text-align: center
  line-height: 0.8

  h1
    font-size: 8em
    font-family: Silkscreen, monospace

  a:hover
    text-shadow: 0 0 4px white

footer
  margin-top: 3rem

  p
    text-align: center
